<template >
  <v-row>
    <v-col>
      <h1 class="mb-4">Hola! {{ getUser.full_name }}</h1>
      <template>
        <v-expansion-panels v-model="value">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h4>Informacón del usuario</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-if="getUser">
                <v-col cols="12" md="6">
                  <p>
                    <span class="font-weight-bold">Nombre</span>:
                    {{ getUser.first_name }}
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <p>
                    <span class="font-weight-bold">Apellido</span>:
                    {{ getUser.last_name }}
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <p>
                    <span class="font-weight-bold">Correo</span>:
                    {{ getUser.email }}
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <p>
                    <span class="font-weight-bold">Rol</span>:
                    {{ getUser.user_role.name }}
                  </p>
                </v-col>
              </v-row>
              <v-row v-else class="py-4 justify-center">
                <Loading />
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-col>
  </v-row>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  name: "MyProfile",
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Mis datos",
      };
    },
  },

  data() {
    return {
      value: 0,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },
};
</script>

<style>
</style>
